<template>
  <v-row class="user-bio-panel">
    <loader-dialog :is-dialog-visible="loaderDialog" />
    <!-- communication profile -->
    <v-col cols="12">
      <v-card class="pt-10">
        <v-alert
          v-model="isAlertVisible"
          :color="error ? 'error' : 'success'"
          dismissible
          text
          transition="slide-y-transition"
        >
          {{ reponseMsg }}
        </v-alert>
        <v-card-title class="justify-center flex-column">
          <v-avatar
            :color="communication.highlight_image ? '' : 'primary'"
            :class="communication.highlight_image ? '' : 'v-avatar-light-bg primary--text'"
            size="120"
            rounded
            class="mb-4"
          >
            <v-img
              v-if="communication.highlight_image"
              :src="communication.highlight_image"
              contain
            ></v-img>
            <span
              v-else-if="communication.title"
              class="font-weight-semibold text-5xl"
            >{{ avatarText(communication.title) }}</span>
            <v-img
              v-else
              :src="require('@/assets/images/avatars/1.png')"
            />
          </v-avatar>

          <span class="mb-2">{{ communication.title }}</span>
        </v-card-title>

        <v-card-text>
          <h2 class="text-xl font-weight-semibold mb-2">
            Détails
          </h2>

          <v-divider></v-divider>

          <v-list>
            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">
                <v-icon size="15">{{ icons.mdiAccount }}</v-icon>
                Titre:
              </span>
              <span class="text--secondary font-weight-bold">{{ communication.title }}</span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium text-no-wrap me-2">
                <v-icon size="15">{{ icons.mdiTextAccount }}</v-icon>
                Enregistré par:
              </span>
              <span class="text--secondary font-weight-bold">{{ communication.user.name }} {{ communication.user.last_name }}</span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">
                <v-icon size="15">{{ icons.mdiAccountQuestion }}</v-icon>
                Avec notification push:
              </span>
              <span class="text--secondary text-capitalize font-weight-bold">
                <v-chip
                  small
                  :color="communication.with_push_notif === true ? 'success' : 'error'"
                >
                  {{ communication.with_push_notif === true ? "OUI" : "NON" }}
                </v-chip>
              </span>
            </v-list-item>
            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">
                <v-icon size="15">{{ icons.mdiCalendar }}</v-icon>
                Créée le:
              </span>
              <span class="text--secondary font-weight-bold">{{ communication.created_at !== null ? formattingDate(communication.created_at) : null }}</span>
            </v-list-item>
            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">
                <v-icon size="15">{{ icons.mdiCalendar }}</v-icon>
                Modifiée le:
              </span>
              <span class="text--secondary font-weight-bold">{{ communication.updated_at !== null ? formattingDate(communication.updated_at) : null }}</span>
            </v-list-item>
            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">
                <v-icon size="15">{{ icons.mdiCalendar }}</v-icon>
                Supprimée le:
              </span>
              <span class="text--secondary font-weight-bold">{{ communication.deleted_at !== null ? formattingDate(communication.deleted_at) : null }}</span>
            </v-list-item>
          </v-list>
        </v-card-text>

        <v-card-actions class="justify-center">
          <v-tooltip
            color="info"
            left
          >
            <template #activator="{ on, attrs }">
              <v-btn
                small
                class="mb-4 me-3 mt-4"
                v-bind="attrs"
                color="info"
                outlined
                v-on="on"
                @click="openForm(communication)"
              >
                <v-icon
                  dark
                >
                  {{ icons.mdiPencilOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>Modifier</span>
          </v-tooltip>
          <v-tooltip
            :color="communication.deleted_at ? 'primary' : 'error'"
            right
          >
            <template #activator="{ on, attrs }">
              <v-btn
                small
                class="mb-4 me-3 mt-4"
                v-bind="attrs"
                :color="communication.deleted_at ? 'primary' : 'error'"
                outlined
                v-on="on"
                @click="confirmItem(communication)"
              >
                <v-icon
                  dark
                  :color="communication.deleted_at ? 'primary' : 'error'"
                >
                  {{ communication.deleted_at !== null ? icons.mdiDeleteRestore : icons.mdiDeleteOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>{{ communication.deleted_at !== null ? "Restaurer" : "Supprimer" }}</span>
          </v-tooltip>
        </v-card-actions>
      </v-card>
      <communication-form
        :dialog="dialog"
        :object="item"
        @clickout="closeDialog"
        @submitted="loading"
      />
      <confirm-dialog
        :message="messageDialog"
        :dialog-delete="dialogDelete"
        @clickout="dialogDelete = false"
        @submitted="deleteItem"
      />
    </v-col>
  </v-row>
</template>

<script>
import { getCurrentInstance, ref } from '@vue/composition-api'
import { avatarText, customFormatDate, kFormatter } from '@core/utils/filter'
import {
  mdiAccount,
  mdiAccountLock,
  mdiAccountQuestion, mdiBellOffOutline, mdiBellOutline,
  mdiBriefcaseVariantOutline,
  mdiCakeVariant,
  mdiCalendar,
  mdiCalendarEdit,
  mdiCalendarMinus,
  mdiCheck,
  mdiCheckboxBlankCircle, mdiCheckDecagram,
  mdiEmail,
  mdiEmailCheck, mdiFileCheck, mdiHelp, mdiLock,
  mdiLockOpen,
  mdiLogin,
  mdiPencilOutline,
  mdiPhone, mdiPhoneCheck, mdiSmokingOff, mdiTextAccount, mdiThumbUp,
  mdiTranslate, mdiWalletTravel,
  mdiDeleteOutline, mdiDeleteRestore,
} from '@mdi/js'
import LoaderDialog from '@core/components/dialogs/LoaderDialog'
import CommunicationForm from '@core/components/communication/CommunicationForm'
import ConfirmDialog from '@core/components/dialogs/ConfirmDialog'
import { notify } from '@core/utils/notification'
import store from '@/store'

export default {
  name: 'CommunicationPanel',
  components: { ConfirmDialog, CommunicationForm, LoaderDialog },
  model: {
    prop: 'communication',
    event: 'update:is-form-active',
  },
  props: {
    communication: {
      type: Object,
      required: true,
    },
  },

  emits: ['updated'],

  setup() {
    const isAlertVisible = ref(false)
    const dialogConfirm = ref(false)
    const dialogLock = ref(false)
    const loaderDialog = ref(false)
    const isDialogVisible = ref(false)
    const dialog = ref(false)
    const error = ref(false)
    const message = ref(null)
    const reponseMsg = ref(null)
    const item = ref({ links: [] })
    const instance = getCurrentInstance()
    const messageDialog = ref('')
    const dialogDelete = ref(false)

    const resolveUserRoleVariant = role => {
      if (role === 'MEMBER') return 'primary'
      if (role === 'ADMIN') return 'success'

      return 'primary'
    }

    const formattingDate = date => customFormatDate(date, {
      weekday: 'short',
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    })

    const loading = () => {
      isDialogVisible.value = !isDialogVisible.value
    }

    const submitting = () => {
      loaderDialog.value = true
    }

    const errorForm = () => {
      loaderDialog.value = false
    }

    const closeDialog = () => {
      dialog.value = false
      instance.emit('updated')
    }

    const openForm = val => {
      item.value = {
        id: val.id,
        title: val.title,
        highlight_image: val.highlight_image,
        content: val.content,
        with_push_notif: val.with_push_notif,
        links: [],
      }
      dialog.value = true
    }

    const confirmItem = object => {
      messageDialog.value = object.deleted_at === null ? `Voulez-vous supprimer la communication ${object.title}?` : `Voulez-vous restaurer la communication ${object.title}?`
      item.value = object
      dialogDelete.value = true
    }

    const deleteItem = () => {
      if (item.value.deleted_at === null) {
        store.dispatch('communication/deleteCommunication', item.value).then(response => {
          item.value.deleted_at = response.result.deleted_at
          dialogDelete.value = false
          notify(true, true, response.message)
        }).catch(err => {
          message.value = err.response.data.message
          error.value = err.response.data.message
          dialogDelete.value = false
          notify(true, true, err.response.data.message)
        })
      } else {
        store.dispatch('communication/restoreCommunication', item.value).then(response => {
          dialogDelete.value = false
          item.value.deleted_at = response.result.deleted_at
          notify(true, true, response.message)
        }).catch(err => {
          message.value = err.response.data.message
          error.value = err.response.data.message
          dialogDelete.value = false
          notify(true, true, err.response.data.message)
        })
      }
    }

    return {
      deleteItem,
      confirmItem,
      dialogDelete,
      messageDialog,
      item,
      isAlertVisible,
      dialogConfirm,
      dialogLock,
      loaderDialog,
      isDialogVisible,
      dialog,
      error,
      message,
      reponseMsg,
      formattingDate,
      loading,
      submitting,
      errorForm,
      closeDialog,
      openForm,
      avatarText,
      kFormatter,
      resolveUserRoleVariant,

      icons: {
        mdiCheck,
        mdiBriefcaseVariantOutline,
        mdiCheckboxBlankCircle,
        mdiAccountLock,
        mdiLockOpen,
        mdiPencilOutline,
        mdiAccount,
        mdiPhone,
        mdiEmail,
        mdiCalendar,
        mdiCalendarMinus,
        mdiCalendarEdit,
        mdiCakeVariant,
        mdiAccountQuestion,
        mdiTranslate,
        mdiLogin,
        mdiEmailCheck,
        mdiPhoneCheck,
        mdiFileCheck,
        mdiTextAccount,
        mdiThumbUp,
        mdiCheckDecagram,
        mdiHelp,
        mdiSmokingOff,
        mdiWalletTravel,
        mdiLock,
        mdiBellOutline,
        mdiBellOffOutline,
        mdiDeleteOutline,
        mdiDeleteRestore,
      },
    }
  },
}
</script>

<style scoped>

</style>
