<template>
  <div>
    <v-card class="mb-3">
      <v-card-title>
        <span>Détails de la communication</span>
        <v-spacer></v-spacer>
        <v-tooltip
          left
          color="primary"
        >
          <template #activator="{ on, attrs }">
            <v-btn
              color="primary"
              class="me-n3 mt-n2"
              v-bind="attrs"
              icon
              v-on="on"
              @click="prev"
            >
              <v-icon>
                {{ icons.mdiArrowLeftBoldCircleOutline }}
              </v-icon>
            </v-btn>
          </template>
          <span>Précédent</span>
        </v-tooltip>
        <v-tooltip
          bottom
          color="secondary"
        >
          <template #activator="{ on, attrs }">
            <v-btn
              color="secondary"
              class="me-n3 mt-n2 ml-2"
              v-bind="attrs"
              icon
              v-on="on"
              @click="refreshObjet"
            >
              <v-icon>
                {{ icons.mdiRefresh }}
              </v-icon>
            </v-btn>
          </template>
          <span>Raffraichir</span>
        </v-tooltip>
      </v-card-title>
    </v-card>
    <!--    <loader-dialog :is-dialog-visible="isDialogVisible" />-->
    <v-row>
      <v-col
        cols="12"
        md="5"
        lg="4"
      >
        <v-skeleton-loader
          v-show="isDialogVisible"
          class="mx-auto border"
          max-width="500"
          type="card, divider, list-item-three-line, list-item-three-line, list-item-three-line, list-item, actions"
        ></v-skeleton-loader>
        <communication-panel
          v-show="!isDialogVisible"
          :communication="$store.state.communication.communication"
          @updated="itemUpdated"
        />
      </v-col>
      <v-col
        cols="12"
        md="7"
        lg="8"
      >
        <v-skeleton-loader
          v-show="isDialogVisible"
          class="mx-auto border"
          type="actions, divider, card-avatar, article"
        ></v-skeleton-loader>
        <v-card
          v-show="!isDialogVisible"
          v-scroll.self="onScroll"
          class="overflow-y-auto"
          max-height="530"
        >
          <v-banner
            class="justify-center text-h5 font-weight-light"
            sticky
            style="background-color: rgba(156,163,211,0.87);"
          >
            Contenu
          </v-banner>

          <v-card-text>
            <p v-html="$store.state.communication.communication.content"></p>
          </v-card-text>
        </v-card>
        <v-skeleton-loader
          v-show="!isLinksVisible"
          class="mx-auto mt-2"
          type="text, text, text"
        ></v-skeleton-loader>
        <v-card-actions
          v-show="isLinksVisible"
          v-scroll.self="onScrollLink"
          class="overflow-x-auto"
        >
          <v-btn
            v-for="(link, index) in $store.getters['link/links']"
            v-show="isLinksVisible"
            :id="index"
            class="ml-2"
            small
            :href="link.url"
            target="_blank"
            color="secondary"
          >
            {{ link.label }}
          </v-btn>
        </v-card-actions>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { getCurrentInstance, onMounted, ref } from '@vue/composition-api'
import {
  mdiArrowLeftBoldCircleOutline,
  mdiImageText,
  mdiLinkBox,
  mdiRefresh,
} from '@mdi/js'
import LoaderDialog from '@core/components/dialogs/LoaderDialog'
import CommunicationPanel from '@core/components/communication/CommunicationPanel'
import CommunicationLinks from '@core/components/communication/CommunicationLinks'
import store from '@/store'
import router from '@/router'

export default {
  name: 'CommunicationDetails',
  components: { CommunicationLinks, CommunicationPanel, LoaderDialog },
  setup() {
    const currentPage = ref(null)
    const lastPage = ref(null)
    const userTab = ref(null)
    const isDialogVisible = ref(false)
    const isLinksVisible = ref(false)
    const route = getCurrentInstance().proxy.$route

    const scrollInvoked = ref(0)
    const scrollInvokedLink = ref(0)
    const updatedCom = ref(0)

    const getItem = () => {
      const id = route.params.id !== undefined ? route.params.id : null
      if (id !== null) {
        if (store.state.communication.communication === null || store.state.communication.communication.id !== id) {
          isDialogVisible.value = true
          store.dispatch('communication/getCommunication', id).then(() => {
            getCommunicationLinks(id)
            isDialogVisible.value = false
          }).catch(error => {
            console.log(error)
            router.back()
          })
        } else getCommunicationLinks(id)
      }
    }

    const getCommunicationLinks = (id, page = 1) => {
      setTimeout(() => {
        store.state.link.field.communication_id = id
        store.state.link.field.user = null
        isLinksVisible.value = false
        store.dispatch('link/getLinks', { page, field: store.state.link.field }).then(() => {
          currentPage.value = store.getters['link/current_page']
          lastPage.value = store.getters['link/last_page']
          isLinksVisible.value = true
        })
      }, 1000)
    }

    const refreshObjet = () => {
      const id = route.params.id !== undefined ? route.params.id : null
      if (id !== null) {
        isDialogVisible.value = true
        isLinksVisible.value = false
        store.dispatch('communication/getCommunication', id).then(() => {
          getCommunicationLinks(id)
          isDialogVisible.value = false
        }).catch(error => {
          console.log(error)
          router.back()
        })
      }
    }

    const tabs = [
      { icon: mdiImageText, title: 'Contenu' },
      { icon: mdiLinkBox, title: 'Liens' },
    ]

    const prev = () => {
      router.back()
    }

    onMounted(() => {
      getItem()
    })

    const onScroll = () => {
      scrollInvoked.value++
    }

    const onScrollLink = () => {
      scrollInvokedLink.value++
    }

    const itemUpdated = () => {
      updatedCom.value++
      getCommunicationLinks(route.params.id)
    }

    return {
      itemUpdated,
      isLinksVisible,
      currentPage,
      lastPage,
      updatedCom,
      onScroll,
      onScrollLink,
      scrollInvoked,
      scrollInvokedLink,
      tabs,
      userTab,
      refreshObjet,
      isDialogVisible,
      getItem,
      prev,
      icons: {
        mdiRefresh,
        mdiArrowLeftBoldCircleOutline,
      },
    }
  },
}
</script>

<style scoped>

</style>
