<template>
  <div>
    <v-card>
      <!-- actions -->
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <!-- search -->
        <v-text-field
          v-model="$store.state.link.field.title"
          placeholder="Rechercher par mot clé"
          label="Rechercher par mot clé"
          outlined
          hide-details
          dense
          class="user-search me-3 mb-4"
          @keyup="filterLinks"
        >
        </v-text-field>

        <v-spacer></v-spacer>
      </v-card-text>

      <!-- table -->
      <v-data-table
        loading-text="Chargement..."
        :headers="headers"
        :items="$store.getters['link/links']"
        :items-per-page="$store.getters['link/per_page']"
        :page.sync="$store.getters['link/current_page']"
        dense
        fixed-header
        height="500"
        hide-default-footer
        :loading="loading"
        @page-count="currentPage = $event"
      >
        <template #[`item.created_at`]="{item}">
          {{ formattingDate(`${item.created_at}`) }}
        </template>
        <template #[`item.updated_at`]="{item}">
          {{ formattingDate(`${item.updated_at}`) }}
        </template>

        <template #[`item.action`]="{item}">
          <v-tooltip
            bottom
            color="primary"
          >
            <template #activator="{ on, attrs }">
              <v-icon
                color="primary"
                small
                v-bind="attrs"
                v-on="on"
                @click.stop="showContentUrl(item.url)"
              >
                {{ icons.mdiEyeOutline }}
              </v-icon>
            </template>
            <span>Voir</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-card-text class="pt-2">
        <v-row>
          <v-col
            lg="2"
            cols="3"
          >
            <v-select
              v-model="$store.state.link.field.paginate"
              :items="itemsPerPage"
              item-text="text"
              item-value="value"
              label="Lignes par page:"
              type="number"
              min="-1"
              max="15"
              hide-details
              @input="getEltsPerPage"
            ></v-select>
          </v-col>

          <v-col
            lg="10"
            cols="9"
            class="d-flex justify-end"
          >
            <v-pagination
              v-model="currentPage"
              total-visible="6"
              :length="lastPage"
              @input="getElts"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <link-content-dialog
      :dialog-content="showDialogContent"
      :url="contentUrl"
      @clickout="showDialogContent = false"
    />
  </div>
</template>

<script>
import { onMounted, ref, watch } from '@vue/composition-api'
import { customFormatDate } from '@core/utils/filter'
import {
  mdiAccountLock,
  mdiDeleteOutline,
  mdiDotsVertical, mdiEyeOutline, mdiLockOpen,
  mdiPencilOutline, mdiPlus,
  mdiSquareEditOutline,
} from '@mdi/js'
import LinkContentDialog from '@core/components/dialogs/LinkContentDialog'
import store from '@/store'

export default {
  name: 'CommunicationLinks',
  components: { LinkContentDialog },
  props: {
    // eslint-disable-next-line vue/prop-name-casing
    communication_id: {
      type: String,
      default: null,
    },
    updated: {
      type: Number,
      default: 0,
    },
  },

  setup(props) {
    const isLockedColor = {
      false: 'info',
      true: 'success',
    }
    const loading = ref(false)
    const currentPage = ref(null)
    const lastPage = ref(null)
    const dialogDelete = ref(false)
    const isDialogVisible = ref(false)
    const isDialogVisible2 = ref(false)
    const dialog = ref(false)
    const contentUrl = ref(null)
    const showDialogContent = ref(false)

    const formattingDate = date => customFormatDate(date, {
      weekday: 'short',
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    })

    const closeDialog = () => {
      dialog.value = false
    }

    const getElts = (page = 1) => {
      loading.value = true
      store.state.link.field.communication_id = props.communication_id
      store.state.link.field.user = null
      store.dispatch('link/getLinks', { page, field: store.state.link.field }).then(() => {
        currentPage.value = store.getters['link/current_page']
        lastPage.value = store.getters['link/last_page']
        loading.value = false
      })
    }

    const filterElts = () => {
      setTimeout(() => {
        getElts()
      }, 1000)
    }

    // eslint-disable-next-line camelcase
    const getEltsPerPage = per_page => {
      // eslint-disable-next-line camelcase
      if (per_page !== null) {
        loading.value = true
        // eslint-disable-next-line camelcase
        store.state.link.field.paginate = per_page
        store.state.link.field.communication_id = props.communication_id
        store.dispatch('link/getLinks', {
          page: store.getters['link/current_page'],
          field: store.state.link.field,
        }).then(() => {
          currentPage.value = store.getters['link/current_page']
          lastPage.value = store.getters['link/last_page']
          loading.value = false
        })
      }
    }

    onMounted(() => {
      setTimeout(() => {
        getElts(store.getters['link/current_page'])
      }, 500)
    })

    const filterLinks = () => {
      setTimeout(() => {
        getElts(store.getters['link/current_page'])
      }, 1000)
    }

    const showContentUrl = val => {
      contentUrl.value = val
      showDialogContent.value = true
    }

    watch(() => props.updated, updated => {
      console.log("pp")
      filterLinks()
    })

    return {
      contentUrl,
      showDialogContent,
      showContentUrl,
      filterLinks,
      filterElts,
      formattingDate,
      closeDialog,
      loading,
      getEltsPerPage,
      getElts,
      currentPage,
      lastPage,
      dialog,
      dialogDelete,
      isDialogVisible,
      isDialogVisible2,
      headers: [
        { text: 'LIBELLE', value: 'label', sortable: false },
        { text: 'LIEN', value: 'url', sortable: false },
        { text: 'DATE DE CREATION', value: 'created_at', sortable: false },
        { text: 'DATE DE MISE A JOUR', value: 'updated_at', sortable: false },
        { text: 'ACTION', value: 'action', sortable: false },
      ],
      itemsPerPage: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '15', value: 15 },
        { text: '20', value: 20 },
      ],
      status: {
        1: 'Current',
        2: 'Professional',
        3: 'Rejected',
        4: 'Resigned',
        5: 'Applied',
      },
      is_locked: {
        1: true,
        2: false,
      },
      isLockedColor,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiAccountLock,
        mdiLockOpen,
        mdiPlus,
        mdiEyeOutline,
      },
    }
  },
}
</script>

<style scoped>
.v-data-table-header th {
  white-space: nowrap;
}
</style>
